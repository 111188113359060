.sml-fw-bold,
span.sml-fw-bold {
  font-family: Inter, "Cera Pro Bold" !important;
  font-weight: 700;
}

.sml-fw-medium {
  font-family: Inter, "Cera Pro Medium";
  font-weight: 600;
}

.sml-text-green {
  color: #46b17d;
}
.sml-text-orange {
  color: #deb00e;
}
.sml-text-red {
  color: #b60525 !important;
}
