.sml-disc-badge {
  position: relative;
  display: inline-flex;
  width: 6rem;
  height: 6rem;
  border-radius: 3rem;
  box-sizing: border-box;
  font-family: "Cera Pro Medium" !important;
  line-height: 0.8rem;
  font-size: 0.8rem;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  text-align: center;
  &.sml-disc-badge-white {
    background-color: #ffffff;
    color: #092a39;
  }
  &.sml-disc-badge-skyblue {
    background-color: #a3ddf5;
    color: #092a39;
  }
  &.sml-disc-badge-lightblue {
    background-color: #f4fafd;
    color: #0b8ad2;
  }
  &.sml-disc-badge-lightpurple {
    background-color: #d8d9ee;
    color: #070955;
  }
  &.sml-disc-badge-purple {
    background-color: #a1a4f7;
    color: #070955;
  }
  &.sml-disc-badge-green {
    background-color: #8cf3a2;
    color: #0b511a;
  }
  &.sml-disc-badge-peach {
    background-color: #fc8a83;
    color: #5c0015;
  }
  &.sml-disc-badge-lightyellow {
    background-color: #fefbf1;
    color: #7e6c11;
  }
  &.sml-disc-badge-yellow {
    background-color: #f8edba;
    color: #3f3609;
  }
  &.sml-disc-badge-gray {
    background-color: #7c7d8d;
    color: #f1f1fe;
  }
  &.sml-disc-skyblue-stroked {
    border-width: 8px;
    border-style: solid;
    border-color: #a3ddf5;
  }
  &.sml-disc-badge-new {
    background-color: #cc0a5b;
    color: #f0c4d6;
    font-family: "Cera Pro Bold" !important;
  }
  &.sml-disc-badge-open {
    background-color: #5171b8;
    color: #cdd6eb;
    font-family: "Cera Pro Bold" !important;
  }
  &.sml-disc-badge-closed {
    background-color: #a7a8b6;
    color: #52535a;
    font-family: "Cera Pro Bold" !important;
  }
  &.sml-disc-badge-solved {
    background-color: #259e5b;
    color: #d9f8e7;
    font-family: "Cera Pro Bold" !important;
  }
  &.sml-disc-badge-undefined {
    background-color: #e3ebe7;
    color: #a9afac;
    font-family: "Cera Pro Bold" !important;
  }
  &.sml-disc-badge-other {
    background-color: #d2cad6;
    color: #504b53;
    font-family: "Cera Pro Bold" !important;
  }
  &.sml-disc-badge-user {
    background-color: #97723a;
    color: #ffffff;
    font-family: "Cera Pro Bold" !important;
  }
  &.sml-disc-badge-software {
    background-color: #5a728a;
    color: #ffffff;
    font-family: "Cera Pro Bold" !important;
  }
}
