h1 {
  font-family: "Cera Pro Bold", sofia-pro, sans-serif;
  font-size: 2.5rem;
  font-weight: 900;
}
h2 {
  font-family: "Cera Pro Medium", sans-serif;
  font-size: 1.75rem;
  font-weight: 500;
  margin-bottom: 1rem;
}
h3 {
  font-family: "Cera Pro Medium", sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
}

h4 {
  font-family: "Cera Pro Medium", sofia-pro, sans-serif;
  font-size: 1.15rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

h5 {
  font-family: sofia-pro, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: hsl(212deg, 100%, 50%);
}
