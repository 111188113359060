.dropdown-menu {
    border-width: 0;
    position: absolute;
    left: 0;
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 0.85rem;
    &.dropdown-menu-warranty {
        ul {
            margin-top: 2px;
            li {
                background: white;
                &:hover {background: $green-100;}
            }
        }
        .dropdown-item {
            background: white;
            padding: 0.5rem 1.5rem !important;
            &:hover {background: $green-100;}
        }
    }
    &.dropdown-menu-resources {
        ul {
            margin-top: 2px;
            li {
                background: white;
                &:hover {background: $red-100;}
            }
        }
        .dropdown-item {
            background: white;
            padding: 0.5rem 1.5rem !important;
            &:hover {background: $red-100;}
        }
    }
}