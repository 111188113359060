.fruity-badge {
    font-size: 0.7rem;
    font-family: "Cera Pro Regular";
    padding: 0.3rem .65rem !important;
    border-radius: 0.15rem !important;
    transition: all .25s;
    $background_color: #f7f7f9;
    $color: #000c2b;
    background: $background_color;
    color: $color;
    &.fruity-lg {
        padding: 0.5rem 1.25rem !important;
        font-size: 0.85rem !important;
        font-family: "Cera Pro Medium";
    }
    &.fruity-blueberry {
        $background_color: #e8e8ef;
        $color: #33348b;
        background: $background_color;
        color: $color;
        &.fruity-filled {
            background: $color;
            color: $background_color;
        }
    }
    &.fruity-mint {
        $background_color: #ebefe8;
        $color: #47ad3a;
        background: $background_color;
        color: $color;
        &.fruity-filled {
            background: $color;
            color: $background_color;
        }
    }
    &.fruity-strawberry {
        $background_color: #f8cece;
        $color: #aa1111;
        background: $background_color;
        color: $color;
        &.fruity-filled {
            background: $color;
            color: $background_color;
        }
    }
    &.fruity-raspberry {
        $background_color: #eee8e9;
        $color: #aa1132;
        background: $background_color;
        color: $color;
        &.fruity-filled {
            background: $color;
            color: $background_color;
        }
    }
    &.fruity-icemint {
        $background_color: #e8eced;
        $color: #1b7eab;
        background: $background_color;
        color: $color;
        &.fruity-filled {
            background: $color;
            color: $background_color;
        }
    }
    &.fruity-orange {
        $background_color: #f2ebe7;
        $color: #f27900;
        background: $background_color;
        color: $color;
        &.fruity-filled {
            background: $color;
            color: $background_color;
        }
    }
    &.fruity-pineapple {
        $background_color: #faf097;
        $color: #be5f00;
        background: $background_color;
        color: $color;
        &.fruity-filled {
            background: $color;
            color: $background_color;
        }
    }
    .cera-medium {
        font-family: "Cera Pro Medium";
    }
    .cera-bold {
        font-family: "Cera Pro Bold";
    }
}