.badge {
  &.sml-badge {
    background-color: #eaeaf5;
    color: rgb(42, 44, 78);
    border-radius: 0.25rem;
    padding: 0.5rem 1.25rem;
    font-size: 0.9rem;
    &.sml-pico-badge {
      position: relative;
      display: inline-block;
      width: 0.75rem;
      height: 0.75rem;
      background-color: #c8d0d8;
      text-indent: -999em;
      margin: 0 0.1rem;
    }
    &.sml-pico-badge-support {
      transform: rotate(45deg);
      width: 0.6rem;
      height: 0.6rem;
      margin: 0 0.25rem;
    }
    &.sml-pico-badge-accepted {
      background-color: #8cf3a2;
    }
    &.sml-pico-badge-rejected {
      background-color: #fc8a83;
    }
    &.sml-pico-badge-red {
      background-color: #0b8ad2;
    }
    &.sml-pico-badge-red_answered {
      background-color: #0b1fd2;
    }
    &.sml-pico-badge-delegate {
      background-color: #f5e8a3;
    }
    &.sml-pico-badge-new {
      background-color: #a3ddf5;
    }
    &.sml-pico-badge-open {
      background-color: #a3ddf5;
    }
    &.sml-pico-badge-hold {
      background-color: #d8d9ee;
    }
    &.sml-pico-badge-solved {
      background-color: #a1a4f7;
    }
    &.sml-pico-badge-closed {
      background-color: #7c7d8d;
    }
    &.sml-badge-default {
      background-color: #c9cae9;
    }
    &.sml-badge-peach {
      background-color: #f7dbd9;
      color: #8a3c38;
    }
  }
  &.badge-disc {
    margin: 0;
    position: relative;
    display: inline-flex;
    width: 5.5rem;
    height: 5.5rem;
    border-radius: 3rem;
    box-sizing: border-box;
    font-family: "Cera Pro Medium" !important;
    line-height: 0.8rem;
    font-size: 0.75rem !important;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    text-align: center;
    background-color: #f5f7fa;
    color: #373c44;
    white-space: initial;
    border: 3px solid white;
    &.badge-new {
      background-color: #e6f7fe;
      color: #0597d6;
    }
    &.badge-purple,
    &.badge-hold {
      background-color: #f0e3fe;
      color: #373c44;
    }
    &.badge-green,
    &.badge-success {
      background-color: #c9f3e4;
      color: #108e60;
    }
    &.badge-red,
    &.badge-danger {
      background-color: #ffd7d7;
      color: #c51616;
    }
    &.badge-watermelon {
      background-color: #f20028;
      color: #ffffff;
    }
    &.badge-solved,
    &.badge-filled-blue {
      background-color: #2ea0d1;
      color: #edfeff;
    }
    &.badge-yellow,
    &.badge-warning {
      background-color: #faefd3;
      color: #a17812;
    }
    .label {
      width: 100%;
      font-size: 0.75rem;
    }
  }
}
