header {
  &.page-header {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: hsl(212deg, 50%, 50%);
    height: 150px;
  }
}
