ul.nav {
    &.nav-tabs {

    }
    &.tabs-left {
        li {
            display: block;
            width: 100%;
            float: none;
            a {
                display: block;
                padding: 1rem;
            }
        }
    }
}