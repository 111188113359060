div#sidebar {
  &.c-sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 2000;
    background-color: hsl(212deg, 20%, 96%);
    width: 320px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .c-brand-logo {
      margin-top: 16px;
      height: 120px;
      background-image: url("/img/logo_darkblue.svg");
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }
    .c-sidebar-nav {
      display: flex;
      flex-direction: column;
      .nav-item {
        width: inherit;
        list-style: none;
        border-right: 8px solid transparent;
        .nav-link {
          display: flex;
          //flex-direction: column;
          align-items: center;
          padding: 0.25rem 1.5rem;
          color: #003066;
          .link-icon-wrapper {
            position: relative;
            display: block;
            width: 24px;
            height: 24px;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            margin-bottom: 0.15rem;
            overflow: hidden !important;
            margin-right: 1rem;
            .link-icon {
              position: relative;
              display: block;
              top: 0;
              left: 0;
              width: 24px;
              height: 24px;
              transform: translate3d(0, 0, 0);
              transition: cubic-bezier(0.165, 0.84, 0.44, 1) 200ms;
              &::before {
                position: absolute;
                width: 100%;
                height: 100%;
                content: "";
                transform: translate3d(0, -100%, 0);
                transition: cubic-bezier(0.165, 0.84, 0.44, 1) 200ms;
              }
              &.link-icon-atom {
                background-image: url("/img/sidebar/atom-darkblue.svg");
                &:before {
                  background-image: url("/img/sidebar/atom-primary.svg");
                }
              }
              &.link-icon-atom2 {
                background-image: url("/img/sidebar/atom2-darkblue.svg");
              }
              &.link-icon-bike {
                background-image: url("/img/sidebar/bike-darkblue.svg");
                &:before {
                  background-image: url("/img/sidebar/bike-primary.svg");
                }
              }
              &.link-icon-book {
                background-image: url("/img/sidebar/book-darkblue.svg");
                &:before {
                  background-image: url("/img/sidebar/book-primary.svg");
                }
              }
              &.link-icon-document {
                background-image: url("/img/sidebar/document-darkblue.svg");
                &:before {
                  background-image: url("/img/sidebar/document-primary.svg");
                }
              }
              &.link-icon-education {
                background-image: url("/img/sidebar/education-darkblue.svg");
                &:before {
                  background-image: url("/img/sidebar/education-primary.svg");
                }
              }
              &.link-icon-newspaper {
                background-image: url("/img/sidebar/newspaper-darkblue.svg");
                &:before {
                  background-image: url("/img/sidebar/newspaper-primary.svg");
                }
              }
              &.link-icon-paperplane {
                background-image: url("/img/sidebar/paperplane-darkblue.svg");
                &:before {
                  background-image: url("/img/sidebar/paperplane-primary.svg");
                }
              }
              &.link-icon-laposte {
                background-image: url("/img/sidebar/laposte-darkblue.svg");
                &:before {
                  background-image: url("/img/sidebar/laposte-primary.svg");
                }
              }
              &.link-icon-power {
                background-image: url("/img/sidebar/power-darkblue.svg");
                &:before {
                  background-image: url("/img/sidebar/power-primary.svg");
                }
              }
              &.link-icon-repair {
                background-image: url("/img/sidebar/repair-darkblue.svg");
                &:before {
                  background-image: url("/img/sidebar/repair-primary.svg");
                }
              }
              &.link-icon-shop {
                background-image: url("/img/sidebar/shop-darkblue.svg");
                &:before {
                  background-image: url("/img/sidebar/shop-primary.svg");
                }
              }
              &.link-icon-talk {
                background-image: url("/img/sidebar/talk-darkblue.svg");
                &:before {
                  background-image: url("/img/sidebar/talk-primary.svg");
                }
              }
              &.link-icon-user {
                background-image: url("/img/sidebar/user-darkblue.svg");
                &:before {
                  background-image: url("/img/sidebar/user-primary.svg");
                }
              }
              &.link-icon-video {
                background-image: url("/img/sidebar/video-darkblue.svg");
                &:before {
                  background-image: url("/img/sidebar/video-primary.svg");
                }
              }
            }
          }
          &:hover {
            .link-icon-wrapper {
              .link-icon {
                display: block;
                width: 24px;
                height: 24px;
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
                margin-bottom: 0.15rem;
                transform: translate3d(0, 100%, 0);
              }
            }
          }
        }
        &.nav-item-active {
          border-color: hsl(212deg, 100%, 50%);
        }
      }
    }
    .c-sidebar-subnav {
      display: flex;
      flex-direction: column;
      margin: 0;
      margin-bottom: 0;
      .nav-item {
        .nav-link {
          padding: 0.25rem 1.5rem;
          color: #003066;
          display: flex;
          align-items: center;
        }
      }
    }
    .c-sidebar-bottom-nav {
      display: flex;
      flex-direction: column;
      margin: 0;
      margin-bottom: 0;
      .nav-item {
        .nav-link {
          padding: 0.75rem 1.5rem;
          color: white;
          display: flex;
          align-items: center;
          &.logout {
            background: hsl(349deg, 78%, 50%);
            &:hover {
              background: hsl(349deg, 78%, 40%)
            }
          }
          .link-icon-wrapper {
            position: relative;
            display: block;
            width: 24px;
            height: 24px;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            margin-bottom: 0.15rem;
            overflow: hidden !important;
            margin-right: 1rem;
            .link-icon {
              position: relative;
              display: block;
              top: 0;
              left: 0;
              width: 24px;
              height: 24px;
              transform: translate3d(0, 0, 0);
              transition: cubic-bezier(0.165, 0.84, 0.44, 1) 200ms;
              &::before {
                position: absolute;
                width: 100%;
                height: 100%;
                content: "";
                transform: translate3d(0, -100%, 0);
                transition: cubic-bezier(0.165, 0.84, 0.44, 1) 200ms;
              }
              &.link-icon-power {
                background-image: url("/img/sidebar/power-white.svg");
                &:before {
                  background-image: url("/img/sidebar/power-white.svg");
                }
              }
            }
          }
        }
      }
    }
  }
}
