.status-badge {
    //padding: 1rem 2rem;
    display: flex;
    font-size: 0.85rem;
    border-radius: 50px;
    width: 5.5rem;
    height: 5.5rem;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Cera Pro Bold", sofia-pro, proxima-nova, sans-serif;
    &.status-default {
        background-color: #cccccc;
        color: #111114;
        border-color: #cccccc;
        border-width: 2px;
    }
    &.status-incomplete {
        background-color: #dfd3a1;
        color: #645f57;
        border-color: #cccccc;
    }
    &.status-sent {
        background-color: #cccccc;
        color: #111114;
        border-color: #138ddf;
    }
    &.status-new {
        background-color: #f4faff;
        color: #454566;
        border: 2Px solid #138ddf;
    }
    &.status-open {
        background-color: #138ddf;
        color: #ffffff;
        border-color: #138ddf;
    }
    &.status-pending {
        background-color: #6d4f91;
        color: #ffffff;
        border-color: #6d4f91;
    }
    &.status-hold {
        background-color: #6d4f91;
        color: #33100b;
        border-color: #6d4f91;
    }
    &.status-solved {
        background-color: #25ac62;
        color: #ffffff;
        border-color: #25ac62;
    }
    &.status-closed {
        background-color: #454566;
        color: #ffffff;
        border-color: #454566;
    }
    &.status-rejected {
        background-color: #d8413c;
        color: #ffffff;
        border-color: #d8413c;
    }
}