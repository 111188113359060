.btn {
  font-family: "Cera Pro Regular", proxima-nova, sans-serif;
  border-radius: 0.2rem;
  &.btn-light {
    border: 0;
    background: rgb(225, 225, 245);
    color: rgb(83, 83, 116);
    &:hover {
      color: rgb(225, 225, 245);
      background: rgb(118, 118, 168);
    }
  }
  &.btn-primary {
    background: #007bff;
    border: 1px solid #007bff;
    color: #ffffff;
    &:hover {
      background: darken($color: #007bff, $amount: 10%);
      color: #ffffff;
    }
    &.btn-outline {
      background: #ffffff;
      border: 1px solid #007bff;
      color: #007bff;
      &:hover {
        background: #007bff;
        border: 1px solid #007bff;
        color: #fffffe;
      }
    }
  }
  &.btn-danger {
    background: #d80332;
    border: 1px solid #d80332;
    color: #ffffff;
    transition: all 0.25s;
    &:hover {
      background: darken($color: #a30225, $amount: 10%);
      color: #ffffff;
    }
    &.btn-outline {
      background: #ffffff;
      border: 1px solid #d80332;
      color: #d80332;
      &:hover {
        background: #a30225;
        border: 1px solid #a30225;
        color: #ffffff;
      }
    }
  }
  &.btn-sm {
    font-size: 0.75rem;
    padding: 0.25rem 0.75rem;
  }
  a:link {
    font-family: "Cera Pro Regular", proxima-nova, sans-serif;
  }
  &.btn-cyan {
    transition: all 0.25s;
    background: $cyan-600;
    color: #ffffff;
    &:hover {
      background: $pink-600;
      color: #ffffff;
    }
  }
  &.btn-success {
    background: rgb(6, 146, 81);
    color: #ffffff;
  }
  &.btn-blue {
    background: #1a2fa4;
    color: #ffffff;
    &:hover {
      background: darken($color: #1a2fa4, $amount: 5%);
    }
  }
  &.btn-flat {
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
    background-color: rgb(83, 83, 116);
    border: 0 !important;
    border-radius: 0.35rem;
    &.primary {
      background-color: hsl(212deg, 100%, 50%) !important;
      font-weight: 600;
      border: 0;
      color: white;
      &:hover {
        background-color: hsl(212deg, 100%, 44%) !important;
      }
    }
    &.danger {
      background-color: hsl(349deg, 78%, 53%);
      color: white;
      &:hover {
        background-color: hsl(349deg, 78%, 33%) !important;
      }
    }
    &.success {
      background-color: hsl(158deg, 100%, 38%);
      color: white;
      &:hover {
        background-color: hsl(158deg, 100%, 28%) !important;
      }
    }
    box-shadow: none !important;
    &:focus {
      box-shadow: none !important;
    }
    &.btn-sm {
      font-size: 0.8rem !important;
      padding: 0.5rem 1.5rem !important;
      border-radius: 0.25rem !important;
    }
  }
}
