a.circle-button, button.circle-button {
    font-size: 0.7rem;
    display: flex;
    border-radius: 50px;
    width: 5.5rem;
    height: 5.5rem;
    line-height: 0.85rem;
    text-align: center;
    box-sizing: border-box;
    background: rgb(61, 61, 90);
    color: #ffffff;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0.5rem;
    text-decoration: none;
    cursor: pointer;
    transition: all .15s;
    border: 6px solid rgb(175, 175, 204);
    outline: none;
    margin: 0.5rem;
    &:hover {
        color: #ffffff !important;
        background:rgb(48, 48, 119);
        border-width: 0px;
    }
    &.cb-light {
        background:rgb(217, 217, 233);
        border-color:rgb(238, 238, 247);
        color:rgb(33, 33, 86);
        &:hover {
            border: 2px solid rgb(79, 212, 119);
            background:rgb(184, 184, 206);;
        }
    }
    &.cb-ready {
        background-color: rgb(2, 165, 111);
        border-color:rgb(165, 223, 203);
        color: #ffffff;
        &:hover {
            border-width: 6px;
            background:rgb(2, 165, 111);
        }
    }
    &.cb-skyblue {
        background-color: rgb(26, 132, 193);
        border-color:rgb(34, 148, 214);
        color: #ffffff;
    }
}