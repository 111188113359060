.overhaul {
  display: grid;
  grid-template-columns: 1fr 1fr auto auto auto;
  background-color: white;
  &__product {

  }
  &__comment {

  }
  &__attachements {
    width: 220px;
  }
  &__dates {
    width: 160px;
  }
  &__status {
    width: 160px;
  }
}