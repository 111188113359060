div#stepper {
    position: relative;
    background: rgba(255,255,255,0.8);
}

div.step {
    //border: 1px solid #dcdee6;
    font-family: proxima-nova, sans-serif;
    font-weight: 700;
    background-color: #dcdee6;
    border-bottom: 1px solid #c3c5cc;
    border-radius: 0.25rem;
    //box-shadow: 0px 2px 3px rgba(47, 42, 53, 0.05);
    transition: all .45s;
    font-size: 0.75rem;
    padding: 1rem !important;
    &.step-done {
        background: #1c95e6;
        border: 1px solid #1288d6;
        small {
            border-color: #ffffff;
        }
    }
    &.step-current {
        background: #d3ebf1;
        color: #052c58;
        border: 1px solid #d7e6f7;
        small {
            border-color: #0b69d4;
        }
    }
    &.step-secondary {
        background: #f7f7fa;
        border: 1px solid #ecedf3;
        color: #434e53;
        // border-top: 1px solid #e2e9ee;
        // border-bottom: 1px solid #9da6aa;
    }
    small {
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        line-height: 1.5rem;
        border: 1px solid #bfc4c7;
        padding: 0;
        margin-bottom: 0.25rem;
        border-radius: 1rem;
    }
}