@font-face {
    font-family: "Cera Pro Black";
    src:    url('/fonts/CeraPro-Black.ttf') format("ttf"),
            url('/fonts/CeraPro-Black.woff') format("woff"),
            url('/fonts/CeraPro-Black.eot') format("eot"),
}

@font-face {
    font-family: "Cera Pro Black Italic";
    src:    url('/fonts/CeraPro-BlackItalic.ttf') format("ttf"),
            url('/fonts/CeraPro-BlackItalic.woff') format("woff"),
            url('/fonts/CeraPro-BlackItalic.eot') format("eot"),
}

@font-face {
    font-family: "Cera Pro Bold";
    src:    url('/fonts/CeraPro-Bold.ttf') format("ttf"),
            url('/fonts/CeraPro-Bold.woff') format("woff"),
            url('/fonts/CeraPro-Bold.eot') format("eot"),
}

@font-face {
    font-family: "Cera Pro BoldItalic";
    src:    url('/fonts/CeraPro-BoldItalic.ttf') format("ttf"),
            url('/fonts/CeraPro-BoldItalic.woff') format("woff"),
            url('/fonts/CeraPro-BoldItalic.eot') format("eot"),
}

@font-face {
    font-family: "Cera Pro Italic";
    src:    url('/fonts/CeraPro-Italic.ttf') format("ttf"),
            url('/fonts/CeraPro-Italic.woff') format("woff"),
            url('/fonts/CeraPro-Italic.eot') format("eot"),
}

@font-face {
    font-family: "Cera Pro LightItalic";
    src:    url('/fonts/CeraPro-LightItalic.ttf') format("ttf"),
            url('/fonts/CeraPro-LightItalic.woff') format("woff"),
            url('/fonts/CeraPro-LightItalic.eot') format("eot"),
}

@font-face {
    font-family: "Cera Pro Light";
    src:    url('/fonts/CeraPro-Light.ttf') format("ttf"),
            url('/fonts/CeraPro-Light.woff') format("woff"),
            url('/fonts/CeraPro-Light.eot') format("eot"),
}

@font-face {
    font-family: "Cera Pro Medium";
    src:    url('/fonts/CeraPro-Medium.ttf') format("ttf"),
            url('/fonts/CeraPro-Medium.woff') format("woff"),
            url('/fonts/CeraPro-Medium.eot') format("eot"),
}

@font-face {
    font-family: "Cera Pro Regular";
    src:    url('/fonts/CeraPro-Regular.ttf') format("ttf"),
            url('/fonts/CeraPro-Regular.woff') format("woff"),
            url('/fonts/CeraPro-Regular.eot') format("eot"),
}

@font-face {
    font-family: "Cera Pro Thin";
    src:    url('/fonts/CeraPro-Thin.ttf') format("ttf"),
            url('/fonts/CeraPro-Thin.woff') format("woff"),
            url('/fonts/CeraPro-Thin.eot') format("eot"),
}