.card {
  &.tiny-card {
    background-color: #f7f7f8;
    border-radius: 0.5rem;
    border: none !important;
    .card-body {
      padding: 1rem 2rem !important;
      font-size: 0.85rem;
      span.label {
        font-family: "Cera Pro Regular" !important;
      }
      span.sublabel {
        font-family: "Cera Pro Bold" !important;
        font-size: 0.75rem;
      }
    }
  }
}
