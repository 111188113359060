.btn {
    &.shdw-button {
        transition: all .15s;
        padding: .5rem 1.25rem;
        border-radius: 0.15rem !important;
        &.shdw-green,
        &.shdw-success {
            border: 1px solid rgb(195, 211, 206) !important;
            background: #f9fcfb;
            color: #0c4e2d;
            &:hover {
                background: #ffffff;
                box-shadow: 0px 3px 8px rgba(16, 119, 67, 0.35);
                border: 1px solid rgb(195, 211, 206) !important;
            }
        }
        &.shdw-primary {
            border: 1px solid rgb(134, 155, 194) !important;
            background: #f9fcfb;
            color: #061d49;
            &:hover {
                background: #ffffff;
                box-shadow: 0px 3px 8px rgba(41, 70, 124, 0.35);
                border: 1px solid rgb(134, 155, 194) !important;
            }
        }
        &.shdw-sm {
            font-size: 0.75rem;
            padding: .25rem .75rem;
        }
    }
}