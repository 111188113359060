a:link, a:visited, a:active {
    color: #007bff;
    text-decoration: none;
    font-family: "Cera Pro Medium", sofia-pro, sans-serif;
    transition: all .25s;
    &:hover {
        color: darken($color: #007bff, $amount: 25%);
    }
    h3 {
        transition: all .25s;
        color: #111114;
        &:hover {
            color: $blue-600;
        }
    }
}