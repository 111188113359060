ul.pagination {
  li.page-item {
    border: 0 !important;
    .page-link {
      border: 0 !important;
      padding: 0.5rem 1rem !important;
      font-size: 0.85rem !important;
      outline: none;
      &:hover {
        background: lightblue;
      }
    }
    &.active {
      .page-link {
        background-color: black;
      }
    }
    &.disabled {
      .page-link {
        background-color: rgb(218, 218, 218);
      }
    }
  }
}
