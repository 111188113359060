@media screen and (max-width: 991px) {
  header {
    nav.navbar {
      background: #ffffff;
      .navbar-toggler {
        border-radius: 0;
        width: 30px;
        height: 30px;
        margin-right: 25px;
        outline: none;
        background: #ffffff !important;
        background: white;

        position: relative;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.5s ease-in-out;
        -moz-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
        cursor: pointer;
        span {
          display: block;
          position: absolute;
          height: 2px;
          width: 100%;
          background: #111111;
          border-radius: 0;
          left: 0;
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
          -webkit-transition: 0.25s ease-in-out;
          -moz-transition: 0.25s ease-in-out;
          -o-transition: 0.25s ease-in-out;
          transition: 0.25s ease-in-out;
          &:nth-child(1) {
            top: 5px;
          }
          &:nth-child(2) {
            top: 14px;
          }
          &:nth-child(3) {
            top: 23px;
          }
        }
        &.open {
          span {
            &:nth-child(1) {
              top: 14px;
              -webkit-transform: rotate(135deg);
              -moz-transform: rotate(135deg);
              -o-transform: rotate(135deg);
              transform: rotate(135deg);
              background: #0d0430;
            }
            &:nth-child(2) {
              opacity: 0;
              left: -60px;
            }
            &:nth-child(3) {
              top: 14px;
              -webkit-transform: rotate(-135deg);
              -moz-transform: rotate(-135deg);
              -o-transform: rotate(-135deg);
              transform: rotate(-135deg);
              background: #d00917;
            }
          }
        }
      }
    }
    .navbar-collapse {
      padding: 0 1rem;
      ul.navbar-nav {
        li.nav-item {
          padding: 0;
          &.nav-item-iconic {
            &:hover {
              border-bottom-color: #fff !important;
            }
            .nav-link {
              padding: 0;
              text-align: center;
              color: #111114;
              .nav-link-icon-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                height: 40px !important;
                .icon {
                  background: #111111;
                  border-radius: 40px;
                  width: 40px;
                  height: 40px;
                  &.icon-warranty {
                    //background: url('/img/navicons/1x/warranty.jpg');
                  }
                  &.icon-masterclass {
                    background: teal;
                  }
                  &.icon-document {
                    background: darkblue;
                  }
                  &.icon-bom {
                    background: orangered;
                  }
                  &.icon-faq {
                    background: olive;
                  }
                  &.icon-pos {
                    background: salmon;
                  }
                  &.icon-contacts {
                    background: violet;
                  }
                }
              }
            }
          }
          a:link {
            font-family: "Cera Pro Bold" !important;
          }
        }
      }
    }
  }
}

header {
  #topbar {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    width: 100%;
    background: #ffffff;
    min-height: 36px;
    font-size: 0.75rem;
    border-bottom: 1px solid rgb(225, 225, 245) !important;
    z-index: 3000;
    .username {
      display: inline-block;
      height: 32px;
      line-height: 2rem;
      padding-left: 1rem;
      padding-right: 1rem;
      border-right: 1px solid rgb(225, 225, 245);
    }
    .btn {
      &.btn-topbar {
        border-radius: 0;
        border: 0;
        padding: 0 1rem;
        line-height: 2rem;
        font-size: 0.75rem;
        height: 32px;
        &.btn-logout {
          color: darkblue;
          background-color: #ffffff;
          font-weight: 600;
          border-bottom: 0px solid rgb(225, 225, 245) !important;
        }
      }
    }
    &.topbar-blue {
      background-color: #0077ff;
      border-bottom: none !important;
      .btn {
        background-color: transparent;
        color: white;
      }
    }
  }
  nav.navbar {
    font-family: sofia-pro, sans-serif;
    background: #ffffff;
    border-bottom: 0px solid rgba(124, 127, 138, 1) !important;
    box-shadow: 0px -10px 20px rgba(39, 60, 136, 0.5);
    padding: 0;
    margin-top: 33px;
    .navbar-brand {
      width: 180px;
      height: 90px;
      background: #ffffff;
      padding: 0;
      background-image: url("/img/logo-atelier.jpg");
      @include image-2x("/img/logo-atelier@2x-80.jpg", 180px, 90px);
    }
    .navbar-collapse {
      ul.navbar-nav {
        li.nav-item {
          $left-border-width: 6px;
          $bottom-border-width: 4px;
          padding: 0;
          &.nav-item-iconic {
            transition: all 0.2s;
            cursor: pointer;
            &.nii-warranty {
              .dot {
                background: #20bf6b;
              }
              &:hover {
                border-left: $left-border-width solid #20bf6b;
              }
              &.nii-active {
                border-left: $left-border-width solid #26de81;
              }
              @media screen and (min-width: 992px) {
                border-bottom: 4px solid transparent;
                &:hover {
                  border-left: 0;
                  border-bottom: $bottom-border-width solid #20bf6b;
                }
                &.nii-active {
                  border-left: 0;
                  border-bottom: $bottom-border-width solid #26de81;
                }
              }
            }
            &.nii-masterclass {
              .dot {
                background: #0fb9b1;
              }
              &:hover {
                border-left: $left-border-width solid #0fb9b1;
              }
              &.nii-active {
                border-left: $left-border-width solid #0fb9b1;
              }
              @media screen and (min-width: 992px) {
                border-bottom: 4px solid transparent;
                &:hover {
                  border-left: 0;
                  border-left: 0;
                  border-bottom: $bottom-border-width solid #0fb9b1;
                }
                &.nii-active {
                  border-left: 0;
                  border-left: 0;
                  border-bottom: $bottom-border-width solid #0fb9b1;
                }
              }
            }
            &.nii-documents {
              .dot {
                background: #3867d6;
              }
              &:hover {
                border-left: $left-border-width solid #3867d6;
              }
              &.nii-active {
                border-left: $left-border-width solid #3867d6;
              }
              @media screen and (min-width: 992px) {
                border-bottom: 4px solid transparent;
                &:hover {
                  border-left: 0;
                  border-left: 0;
                  border-bottom: $bottom-border-width solid #3867d6;
                }
                &.nii-active {
                  border-left: 0;
                  border-left: 0;
                  border-bottom: $bottom-border-width solid #3867d6;
                }
              }
            }
            &.nii-bom {
              .dot {
                background: #fa8231;
              }
              &:hover {
                border-left: $left-border-width solid #fa8231;
              }
              &.nii-active {
                border-left: $left-border-width solid #fa8231;
              }
              @media screen and (min-width: 992px) {
                border-bottom: 4px solid transparent;
                &:hover {
                  border-left: 0;
                  border-left: 0;
                  border-bottom: $bottom-border-width solid #fa8231;
                }
                &.nii-active {
                  border-left: 0;
                  border-left: 0;
                  border-bottom: $bottom-border-width solid #fa8231;
                }
              }
            }
            &.nii-faq {
              .dot {
                background: #4b6584;
              }
              &:hover {
                border-left: $left-border-width solid #4b6584;
              }
              &.nii-active {
                border-left: $left-border-width solid #4b6584;
              }
              @media screen and (min-width: 992px) {
                border-bottom: 4px solid transparent;
                &:hover {
                  border-left: 0;
                  border-bottom: $bottom-border-width solid #4b6584;
                }
                &.nii-active {
                  border-left: 0;
                  border-bottom: $bottom-border-width solid #4b6584;
                }
              }
            }
            &.nii-pos {
              .dot {
                background: #8854d0;
              }
              &:hover {
                border-left: $left-border-width solid #f7b731;
              }
              &.nii-active {
                border-left: $left-border-width solid #f7b731;
              }
              @media screen and (min-width: 992px) {
                border-bottom: 4px solid transparent;
                &:hover {
                  border-left: 0;
                  border-bottom: $bottom-border-width solid #f7b731;
                }
                &.nii-active {
                  border-left: 0;
                  border-bottom: $bottom-border-width solid #f7b731;
                }
              }
            }
            &.nii-contacts {
              .dot {
                background: #8854d0;
              }
              &:hover {
                border-left: $left-border-width solid #8854d0;
              }
              &.nii-active {
                border-left: $left-border-width solid #8854d0;
              }
              @media screen and (min-width: 992px) {
                border-bottom: 4px solid transparent;
                &:hover {
                  border-left: 0;
                  border-bottom: $bottom-border-width solid #8854d0;
                }
                &.nii-active {
                  border-left: 0;
                  border-bottom: $bottom-border-width solid #8854d0;
                }
              }
            }
            &.nii-paperplane {
              .dot {
                background: #fc5c65;
              }
              &:hover {
                border-left: $left-border-width solid #fc5c65;
              }
              &.nii-active {
                border-left: $left-border-width solid #fc5c65;
              }
              @media screen and (min-width: 992px) {
                border-bottom: 4px solid transparent;
                &:hover {
                  border-left: 0;
                  border-bottom: $bottom-border-width solid #fc5c65;
                }
                &.nii-active {
                  border-left: 0;
                  border-bottom: $bottom-border-width solid #fc5c65;
                }
              }
            }
            &.nii-youtube {
              .dot {
                background: #eb3b5a;
              }
              &:hover {
                border-left: $left-border-width solid #eb3b5a;
              }
              &.nii-active {
                border-left: $left-border-width solid #eb3b5a;
              }
              @media screen and (min-width: 992px) {
                border-bottom: 4px solid transparent;
                &:hover {
                  border-left: 0;
                  border-bottom: $bottom-border-width solid #eb3b5a;
                }
                &.nii-active {
                  border-left: 0;
                  border-bottom: $bottom-border-width solid #eb3b5a;
                }
              }
            }
            &.nii-laposte {
              .dot {
                background: #ebc23b;
              }
              &:hover {
                border-left: $left-border-width solid #ebc23b;
              }
              &.nii-active {
                border-left: $left-border-width solid #ebc23b;
              }
              @media screen and (min-width: 992px) {
                border-bottom: 4px solid transparent;
                &:hover {
                  border-left: 0;
                  border-bottom: $bottom-border-width solid #ebc23b;
                }
                &.nii-active {
                  border-left: 0;
                  border-bottom: $bottom-border-width solid #ebc23b;
                }
              }
            }
            &.nii-newspaper {
              .dot {
                background: #a5b1c2;
              }
              &:hover {
                border-left: $left-border-width solid #a5b1c2;
              }
              &.nii-active {
                border-left: $left-border-width solid #a5b1c2;
              }
              @media screen and (min-width: 992px) {
                border-bottom: 4px solid transparent;
                &:hover {
                  border-left: 0;
                  border-bottom: $bottom-border-width solid #a5b1c2;
                }
                &.nii-active {
                  border-left: 0;
                  border-bottom: $bottom-border-width solid #a5b1c2;
                }
              }
            }
            &.nii-black {
              .dot {
                background: #0c0c0d;
              }
              &:hover {
                border-left: $left-border-width solid #0c0c0d;
              }
              &.nii-active {
                border-left: $left-border-width solid #0c0c0d;
              }
              @media screen and (min-width: 992px) {
                border-bottom: 4px solid transparent;
                &:hover {
                  border-left: 0;
                  border-bottom: $bottom-border-width solid #0c0c0d;
                }
                &.nii-active {
                  border-left: 0;
                  border-bottom: $bottom-border-width solid #0c0c0d;
                }
              }
            }
            .nav-link {
              display: flex;
              align-items: center;
              font-weight: 800;
              @media screen and (min-width: 992px) {
                display: block;
                padding: 0;
                text-align: center;
                color: #111114;
              }
              .nav-link-icon-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: flex-end;
                height: 55px;
                .icon {
                  background: #111111;
                  border-radius: 40px;
                  width: 40px;
                  height: 40px;
                  &.icon-warranty {
                    background: url("/img/navicons/1x/warranty.jpg");
                    @include image-2x(
                      "/img/navicons/2x/warranty@2x.jpg",
                      40px,
                      40px
                    );
                  }
                  &.icon-masterclass {
                    background: url("/img/navicons/1x/masterclass.jpg");
                    @include image-2x(
                      "/img/navicons/2x/masterclass@2x.jpg",
                      40px,
                      40px
                    );
                  }
                  &.icon-document {
                    background: url("/img/navicons/1x/documents.jpg");
                    @include image-2x(
                      "/img/navicons/2x/documents@2x.jpg",
                      40px,
                      40px
                    );
                  }
                  &.icon-laposte {
                    background: url("/img/navicons/1x/laposte.jpg");
                    @include image-2x(
                      "/img/navicons/2x/laposte@2x.jpg",
                      40px,
                      40px
                    );
                  }
                  &.icon-bom {
                    background: url("/img/navicons/1x/bom.jpg");
                    @include image-2x(
                      "/img/navicons/2x/bom@2x.jpg",
                      40px,
                      40px
                    );
                  }
                  &.icon-faq {
                    background: url("/img/navicons/1x/faq.jpg");
                    @include image-2x(
                      "/img/navicons/2x/faq@2x.jpg",
                      40px,
                      40px
                    );
                  }
                  &.icon-pos {
                    background: url("/img/navicons/1x/pos.jpg");
                    @include image-2x(
                      "/img/navicons/2x/pos@2x.jpg",
                      40px,
                      40px
                    );
                  }
                  &.icon-contacts {
                    background: url("/img/navicons/1x/contacts.jpg");
                    @include image-2x(
                      "/img/navicons/2x/contacts@2x.jpg",
                      40px,
                      40px
                    );
                  }
                  &.icon-paperplane {
                    background: url("/img/navicons/1x/paperplane.jpg");
                    @include image-2x(
                      "/img/navicons/2x/paperplane@2x.jpg",
                      40px,
                      40px
                    );
                  }
                  &.icon-youtube {
                    background: url("/img/navicons/1x/youtube.jpg");
                    @include image-2x(
                      "/img/navicons/2x/youtube@2x.jpg",
                      40px,
                      40px
                    );
                  }
                  &.icon-newspaper {
                    background: url("/img/navicons/1x/newspaper.jpg");
                    @include image-2x(
                      "/img/navicons/2x/newspaper@2x.jpg",
                      40px,
                      40px
                    );
                  }
                  &.icon-customers {
                    background: url("/img/navicons/1x/customers.jpg");
                    @include image-2x(
                      "/img/navicons/2x/customers@2x.jpg",
                      40px,
                      40px
                    );
                  }
                }
              }
              .nav-link-label-wrapper {
                display: flex;
                align-items: flex-start;
                font-weight: 700;
                @media screen and (min-width: 992px) {
                  justify-content: flex-center;
                  align-items: flex-start;
                  height: 44px;
                  padding: 0 0.5rem;
                  line-height: 0.9rem;
                  span {
                    font-size: 0.75rem;
                    width: 100%;
                  }
                }
              }
            }
          }
          a.nav-link {
            font-family: "Cera Pro Bold";
          }
        }
      }
    }
  }
  &.header-blue {
    #topbar {
      background-color: #0077ff;
      border-bottom: none !important;
      .btn {
        background-color: transparent;
        color: white;
      }
    }
    nav.navbar {
      background-color: #006edb !important;
      .navbar-collapse {
        ul.navbar-nav {
          li.nav-item {
            color: white;
            .nav-link {
              color: white;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .nav-item-iconic {
    width: 80px !important;
  }
}

.navbar-collapse.collapse {
  max-height: 80vh;
}
.navbar-collapse.collapse.show {
  max-height: 100vh !important;
  overflow-y: auto !important;
}
