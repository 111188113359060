.masterclass-header {
    //background: #f7f6fc;
    border-radius: 0.5rem;
}
.masterclass-content {
    //background: #faf9fc;
    border-radius: 0.5rem;
}
.masterclass-program {
    //background: #fdfdfd;
    border-radius: 0.5rem;
}