//@import "../global";
//@import "global";

// Variables
@import "variables";
@import "../global";

// Bootstrap
@import "~bootstrap/scss/bootstrap";
@import "fonts";
@import "form";
@import "step";
@import "mixins";
@import "h";
@import "header";
@import "page-header";
@import "dropdown-menu";
@import "button";
@import "card";
@import "badge";
@import "table";
@import "alert";
@import "footer";
@import "pagination";
@import "tabs";
@import "video";
@import "searchbar";
@import "a";
@import "login";
@import "list";
@import "masterclass";
@import "tile";
@import "toast";
@import "switch";
@import "status-badge";
@import "type-badge";
@import "round-badge";
@import "support-badge";
@import "circle-button";
@import "shadowed-button";
@import "fruity-buttons";
@import "fruity-card";
@import "fruity-alert";
@import "fruity-badge";
@import "fruity-input";
@import "zendesk-badge";
@import "topbar-button";
@import "../sml/badge";
@import "foc";
@import "card/tiny-card";
@import "minicard";

@import "logged-in-as";
@import "sidebar";
@import "../flat/alert";
@import "../flat/button";

@import "laposte/overhaul";
html,
body {
  font-family: "Cera Pro Regular", proxima-nova, sans-serif !important;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

main {
  background: #ffffff;
  display: block;
  overflow: hidden;
  clear: both;
}

.sticky-content {
  top: 200px;
}
div#app {
  &.sidebar-nav {
    margin-left: 320px;
  }
}

div#main {
  margin-top: 123px;
  background: #fcfdfd !important;
}

div#live-notifications {
  display: none;
}

@media screen and (min-width: 992px) {
  div#main {
    margin-top: 131px;
  }
}

@media screen and (min-width: 1200px) {
  div#live-notifications {
    display: block;
    border: 1px solid red;
  }
}
// Vue Transitions
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.fade-500-enter-active,
.fade-500-leave-active {
  transition: opacity 0.15s;
  transition-delay: 0.5s;
}
.fade-500-enter, .fade-500-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

div#bom-browser {
  position: relative;
}

div#bom-search {
  position: absolute;
  display: block;
  width: 100%;
}

div#brands-browser {
  position: absolute;
  width: 100%;
}

div#groups-browser {
  position: absolute;
  width: 100%;
}

div#products-browser {
  position: absolute;
  width: 100%;
}

div#bom-results {
  position: relative;
  width: 100%;
}

.vue-dropzone {
  background: #ffffff !important;
  border-radius: 0.25rem;
  //box-shadow: 0px 4px 8px rgba(47, 42, 53, 0.05);
  border: 0 !important;
  &.dropzone {
    background: #f7f5f0 !important;
    transition: all 0.25s;
    &:hover {
      //box-shadow: 0px 8px 12px rgba(47, 42, 53, 0.15);
    }
  }
}

p,
span {
  font-family: "Cera Pro Regular" !important;
}

.fw-bold,
b,
strong {
  font-family: "Cera Pro Medium" !important;
}

.text-primary {
  color: #1a2fa4;
}

.text-blue {
  color: #1a2fa4;
}

#logged-in-as-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  z-index: 9999;
  background: #261da0;
  color: #ffffff;
  background: #1c3daa;
  background: linear-gradient(-45deg, #eb006a, #0456fb, #0c9fe4, #eb001f);
  background: rgba(206, 69, 15, 0.95);
  background-size: 200% 200%;
  animation: gradient 40s ease infinite;
  font-size: 0.75rem !important;
  span {
    font-size: 0.85rem !important;
  }
  a,
  a:link {
    color: #e9e4a1;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 200%;
    background-size: 200% 200%;
  }
  50% {
    background-position: 200% 50%;
    background-size: 400% 400%;
  }
  100% {
    background-position: 0% 200%;
    background-size: 200% 200%;
  }
}

@import "../sml/card";
@import "../sml/alert";
@import "../sml/text";
@import "../sml/disc-badge";
@import "../foc/foc-badge";
