.support-badge {
    //padding: 1rem 2rem;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.85rem;
    display: inline-block;
    border-radius: 5rem;
    width: 5.5rem;
    height: 5.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-sizing: border-box;
    margin: 0.5rem;
    &.support-accepted {
        background-color: #35aa69;
        color: #ffffff;
        border-color: #45996b;
    }
    &.support-rejected {
        background-color: #d8413c;
        color: #ffffff;
        border-color: #d8413c;
    }
    &.support-checking {
        background-color: #6507d1;
        color: #ffffff;
    }
    &.support-default {
        background: #cfdbd5;
        color: #646866;
    }
    &.support-darkgray {
        background: #d7d8b3;
        color: rgb(58, 53, 53);
    }
}