.badge {
  &.foc-badge {
    padding: 0.5rem 1rem;
    font-family: Inter, "Cera Pro Bold";
    border-radius: 3px;
    font-size: 0.8rem;
    &.foc-badge-sm {
      font-size: 0.75rem;
      border-radius: 0.125rem;
      padding: 0.35 0.5rem !important;
    }
    &.foc-badge-blue {
      $color: #0936d7;
      $backgroundColor: #f5f7ff;
      color: $color;
      background-color: $backgroundColor;
      &.foc-filled,
      &.filled {
        color: $backgroundColor;
        background-color: $color;
      }
    }
    &.foc-badge-orange {
      color: #d75509;
      background-color: #fff9f5;
      &.foc-filled {
        color: #fff9f5;
        background-color: #d75509;
      }
    }
    &.foc-badge-turquoise {
      color: #09b2d7;
      background-color: #f5fdff;
    }
    &.foc-badge-red {
      color: #d7091a;
      background-color: #fff5f6;
    }
    &.foc-badge-green {
      color: #07b069;
      background-color: #f5fffb;
    }
    &.foc-badge-purple {
      color: #5c09d7;
      background-color: #f9f5ff;
      &.foc-filled {
        color: #f9f5ff;
        background-color: #380a7e;
      }
    }

    &.foc-badge-UNDEFINED {
      background-color: #d6d6d6;
      color: #363636;
    }
    &.foc-badge-SHIPPING {
      background-color: #f7e6f4;
      color: #c41aa7;
    }
    &.foc-badge-SHIPPED {
      background-color: #c41aa7;
      color: #f2ebf5;
    }
    &.foc-badge-CREDIT {
      background-color: #162288;
      color: #aeb4e6;
    }
    &.foc-badge-ACCOUNTING {
      background-color: #000000;
      color: #ffffff;
    }
    &.foc-badge-MANUFACTURING_DELAY {
      background-color: #d45911;
      color: rgb(245, 229, 220);
    }
    &.foc-badge-RESTOCK_DELAY {
      background-color: #037a7a;
      color: #def1f1;
    }
  }
}
