.type-badge {
    font-weight: 600;
    font-size: 0.75rem;
    display: inline-block;
    border-radius: 40px;
    width: 5rem;
    height: 5rem;
    line-height: 5rem;
    text-align: center;
    box-sizing: border-box;
    &.type-primary {
        background: rgb(51, 51, 167);
        color: #ffffff;
    }
    &.type-info {
        background:rgb(51, 130, 167);
        color: #ffffff;
    }
    &.type-warning {
        background: rgb(244, 164, 96);
        color:rgb(46, 42, 39);
    }
    &.type-success {
        background:rgb(73, 158, 84);
        color: #ffffff;
    }
    &.type-danger {
        background:rgb(230, 67, 67);
        color: #ffffff;
    }
    &.type-light {
        background:rgb(243, 234, 226);
        color:rgb(46, 42, 39)
    }
    &.type-dark {
        background:rgb(46, 42, 39);
        color: #ffffff;
    }
}