footer,
#footer {
  color: darken($color: rgb(225, 225, 245), $amount: 10%);
  font-size: 0.8rem;
  padding-bottom: 4rem;
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      a {
        color: #222222 !important;
      }
    }
  }
  a {
    color: #222222 !important;
  }
}

#info-bar {
  position: fixed;
  height: 3rem;
  line-height: 3rem;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #111c26;
  color: #ffffff;
  z-index: 3000;
  font-size: 1.25rem;
  .flash-news {
    display: inline-flex;
    flex-direction: row;
    padding: 0 1rem;
    .fn-title {
      font-family: "Cera Pro Bold" !important;
      padding: 0px 0.5rem;
      color: #46c8ff;
      text-transform: uppercase;
    }
    .fn-content {
      font-family: "Cera Pro Regular";
      padding: 0px 0.5rem;
    }
  }
}
