.btn {
  &.foc-button {
    background-color: #f7f7f8;
    color: #505968;
    padding: 0.5rem 1.5rem;
    transition: all 0.1s;
    font-family: "Cera Pro Medium";
    box-sizing: border-box;
    font-size: 0.95rem;
    &:hover {
      background-color: #e3e5e8;
      color: #353c45;
    }
    &.foc-small {
      padding: 0.35rem 1.25rem;
      font-size: 0.8rem;
      font-family: "Cera Pro Medium";
    }
    &.foc-primary {
      background-color: #f7f7f8;
      color: #375481;
      &:hover {
        background-color: #547bb6;
        color: #f7f7f8;
      }
    }
    &.foc-eucalyptus {
      background-color: #ffffff;
      color: #378166;
      border: 1px solid #54b692;
      &:hover {
        background-color: #54b692;
        color: #f7f7f8;
      }
    }
  }
}
