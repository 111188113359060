input {
  &.fruity-input {
    background: #ffffff;
    border: 1px solid #dbdfe1;
    padding: 1rem 1.5rem !important;
    box-shadow: none !important;
  }
  &.fruity-serial {
    letter-spacing: 0.5rem;
    font-family: "Cera Pro Bold";
  }
}
textarea {
  &.fruity-textarea {
    background: #ffffff;
    border: 1px solid #dbdfe1;
    padding: 1rem 1.5rem !important;
    box-shadow: none !important;
    font-size: 1.125rem;
  }
}
