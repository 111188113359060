$button_height: 36px;

.btn {
  &.tb-button {
    height: $button_height;
    background: rgba(255, 255, 255, 1);
    color: rgb(68, 75, 92);
    transition: all 0.15s;
    cursor: pointer;
    border-radius: 0 !important;
    font-size: 0.8rem;
    &:hover {
      background: rgb(235, 237, 240);
    }
    &.tb-raspberry {
      color: rgb(185, 33, 40);
      &:hover {
        background: rgb(235, 228, 228);
      }
    }
  }
}
