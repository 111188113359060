.fruity-card {
    font-size: 1.25rem;
    font-family: "Cera Pro Medium" !important;
    padding: 2rem 3rem !important;
    border-radius: 0.35rem !important;
    transition: all .25s;
    border: none !important;
    text-align: center;
    &.fruity-sm {
        font-size: 0.85rem;
        padding: 0.35rem 1rem !important;
        border-radius: 0.25rem !important;
    }
    &.fruity-hoverable {
        cursor: pointer;
    }
    &.fruity-blueberry {
        $background_color: #e8e8ef;
        $color: #33348b;
        background: $background_color;
        color: $color;
        &.fruity-hoverable {
            &:hover {
                background-color: $color;
                color: $background_color;
            }
        }
    }
    &.fruity-mint {
        $background_color: #ebefe8;
        $color: #47ad3a;
        background: $background_color;
        color: $color;
        &.fruity-hoverable {
            &:hover {
                background-color: $color;
                color: $background_color;
            }
        }
    }
    &.fruity-raspberry {
        $background_color: #eee8e9;
        $color: #aa1132;
        background: $background_color;
        color: $color;
        &.fruity-hoverable {
            &:hover {
                background-color: $color;
                color: $background_color;
            }
        }
    }
    &.fruity-icemint {
        $background_color: #e8eced;
        $color: #1b7eab;
        background: $background_color;
        color: $color;
        &.fruity-hoverable {
            &:hover {
                background-color: $color;
                color: $background_color;
            }
        }
    }
}