.card {
  &.card-light {
    background: #fbfaff;
    border: 0;
    border: 1px solid darken(#fbfaff, 4%);
  }
  &.card-smooth {
    //box-shadow: 0px 3px 8px rgba(165, 165, 197, 0.2);
  }
  &.card-lightgray {
    background: #fbfaff;
    border: 0 !important;
    border-radius: 0.5rem;
  }
  &.card-sav {
    .card-body {
      font-size: 0.85rem;
      @media screen and (max-width: 767px) {
        min-height: 2rem;
      }
      .list-group {
        .list-group-item {
          padding: 0.5rem 0;
        }
      }
      .btn {
        border-radius: 2px;
        font-size: 0.85rem;
        font-weight: 500;
        &.btn-link {
          font-family: "Poppins", sans-serif;
          font-weight: 600;
          border: 1px solid #bac1c9;
          background: #ffffff;
          color: #5d6670;
          text-decoration: none;
          &:hover {
            background: #886eb9;
            color: #ffffff;
            border-color: #5d6670;
          }
        }
      }
    }
  }
  &.card-masterclass {
    .cover {
      position: relative;
      div.inner {
        position: absolute;
        bottom: 0;
        padding: 2rem;
      }
    }
    .card-header {
      border-bottom: 0;
      background: #ffffff;
    }
    .card-body {
      .cover {
        border-radius: 2px;
        overflow: hidden;
        margin-bottom: 1.5rem;
      }
      .date {
        margin-bottom: 1.5rem;
        font-family: "Poppins", sans-serif;
        color: midnightblue;
        font-weight: 400;
      }
      .description {
        font-size: 0.85rem;
        margin-bottom: 1.5rem;
      }
    }
    hr {
      width: 80px;
      border-color: #b9b9d3;
      border-width: 2px;
    }
  }
  &.card-info {
    background: #f0f2f5;
    border-radius: 0.25rem;
    border: 1px solid #d6dadf;
    box-shadow: 0 !important;
    padding: 1rem;
  }
  &.card-h180 {
    min-height: 180px;
  }
  &.card-h240 {
    min-height: 240px;
  }
  &.card-hoverable {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0 !important;
    }
    cursor: pointer;
    transition: all 0.25s;
    &:hover {
      box-shadow: 0px 4px 12px rgba(144, 170, 218, 0.4);
      border: 1px solid rgb(186, 203, 226);
    }
  }
  &.card-flat {
    box-shadow: none !important;
    background: #bac1c9;
  }
}
