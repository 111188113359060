.rnd-badge {
    font-weight: 600;
    font-size: 0.5rem;
    display: inline-block;
    border-radius: 40px;
    width: 1rem;
    height: 1rem;
    line-height: 1rem;
    text-align: center;
    box-sizing: border-box;
    &.rnd-badge-sm {
        width: 2rem;
        height: 2rem;
        line-height: 2rem;
    }
    &.rnd-badge-xs {
        width: 1.5rem;
        height: 1.5rem;
        line-height: 1.5rem;
    }
    &.rnd-light {
        background: $gray-400;
    }
    &.rnd-primary {
        background: rgb(51, 51, 167);
        color: #ffffff;
    }
    &.rnd-info {
        background:rgb(51, 130, 167);
        color: #ffffff;
    }
    &.rnd-verified {
        background:rgb(0, 163, 238);
        color: #ffffff;
    }
    &.rnd-warning {
        background: rgb(244, 164, 96);
        color:rgb(46, 42, 39);
    }
    &.rnd-success {
        background:rgb(73, 158, 84);
        color: #ffffff;
    }
    &.rnd-danger {
        background:rgb(230, 67, 67);
        color: #ffffff;
    }
    &.rnd-dark {
        background:rgb(46, 42, 39);
        color: #ffffff;
    }
    &.rnd-zendesk {
        &.rnd-zendesk-off {
            background-image: url('/img/icons/zendesk_off.png');
        }
        &.rnd-zendesk-on {
            background-image: url('/img/icons/zendesk_on.png');
        }
        background-size: cover;
        text-indent: -999rem;
    }
}