div.minicard {
  display: inline-block;
  margin: 0.25rem;
  font-size: 0.75rem;
  .label {
    font-family: "Cera Pro Bold";
  }
  &.minicard-expired {
    color: rgb(209, 0, 0);
  }
}
