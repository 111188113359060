.badge {
    font-size:              .75rem;
    border-radius:          1rem;
    padding-top:            .25rem;
    padding-bottom:         .25rem;
    padding-left:           .5rem;
    padding-right:          .5rem;
    &.badge-xl {
        padding: 0.5rem 1rem;
        font-size: 0.95rem;
    }
    &.badge-primary {
        background: #e0e6f8;
        color: #323fb1;
        border: 1px solid #e0e6f8;
    }
    &.badge-success,
    &.badge-solved {
        background: $teal-500;
        color: #ffffff;
    }
    &.badge-sent {
        background: #ffffff;
        color: #323fb1 !important;
        border: 1px solid #323fb1;
    }
    &.badge-purple {
        background: #5f0be7;
        color: #ffffff;
        i{
            color: #55ebb9 !important;
        }
    }
    &.badge-incomplete {
        background: #f1ebc5;
        color: #3a3227;
    }
    &.soft-radius {
        border-radius: 0.15rem;
    }
    &.no-radius {
        border-radius: 0;
    }
    &.badge-warning {
        background: #f3e9d5;
        color: #928466;
        border: 0;
    }
    &.badge-xl {
        
    }
}