div.video-item {
    div.video-cover {
        border-radius: 4px;
        overflow: hidden;
        margin-bottom: 1rem;
    }
    margin-bottom: 2rem;
    cursor: pointer;
}

#fs-video-player {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,40,120,0.95);
    background: rgba(255,255,255,0.9);
    z-index: 1999;
}

.video-responsive { 
    overflow:hidden; 
    padding-bottom:56.25%; 
    position:relative; 
    height:0;
    }
    
.video-responsive iframe {
    left:0; 
    top:0; 
    height:100%;
    width:100%;
    position:absolute;
    border-radius: 8px;
    overflow: hidden;
}

button.btn-close {
    background: transparent;
    background-image: url('/img/close-80.png');
    @include image-2x('/img/close@2x-80.png', 80px, 80px);
    border: 0;
    position: abslute;
    top: 10px;
    right: 10px;
    width: 80px;
    height: 80px;
    color: #ffffff;
    line-height: 80px;
    outline: none;
    text-indent: -999em;
}