.alert {
    font-family: sofia-pro, sans-serif;
    padding: 1rem 2rem;
    border: 0;
    border-radius: 5px;
    font-size: 0.85rem !important;
    line-height: 1.25rem;
    &.alert-primary {
        background: #0b69d4;
        color: #ffffff;
        border: 0;
    }
    &.alert-warning {
        background: #f3e9d5;
        color: #0f0b03;
        border: 0;
    }
    &.alert-light {
        background: #fafbfc;
        color: #091a2e;
    }
    p, span {
        font-size: 0.85rem !important;
    }
}