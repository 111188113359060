.alert {
  font-family: "Cera Pro Regular" !important;
  &.sml-alert {
    background-color: #f3f8fb;
    display: block;
    font-family: "Cera Pro Medium" !important;
    font-size: 1rem !important;
    border-radius: 0.45rem;
    padding: 1.5rem 1.75rem;
  }
  &.sml-alert-success {
    background-color: #daeed8;
    color: #3b7d36;
  }
  &.sml-alert-white {
    background-color: #ffffff;
    color: #111114;
  }
  &.sml-peach {
    background: #fc9878;
    color: #3b1307;
  }
  &.sml-peach-light {
    background-color: #fad9cf;
    color: #3b1307;
  }
  &.sml-purple-light {
    background-color: #f6f2f9;
    color: #3b0e73;
  }
  &.sml-blue-light {
    background-color: #103b5f;
    color: #cdeff3;
  }
}
