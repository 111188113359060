#loginbox {
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    .card {
        border-radius: 0.25rem;
        padding: 2rem;
    }
    // input.form-control {
    //     background: #ffffff;
    //     border: 1px solid;
    //     outline: none;
    //     box-shadow: none;
    // }
}