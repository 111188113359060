ol, ul {
    padding-left: 0 !important;
}
ul.list-group {
    background: none;
    &.list-group-smooth {
        li.list-group-item {
            background-color: transparent !important;
            border: 1px solid rgb(225, 225, 245);
            border: 0;
            border-bottom: 1px solid #dbdbe7;
            border-radius: 0rem;
            //box-shadow: 0px 2px 4px rgba(47, 42, 53, 0.03);
            transition: all .25s;
            margin-bottom: 0.2rem;
            &.item-hoverable {
                &:hover {
                    border: 1px solid #eeeeee;
                    box-shadow: 0px 8px 12px rgba(47, 42, 53, 0.15);
                }
            }
            &.list-group-item-head {
                background: #ebeef1;
                background: #ffffff;
                color: #0b69d4;
                color: #111114;
                font-weight: 700;
                font-size: 1.25rem;
                font-family: proxima-nova, sans-serif;
                border: 1px solid rgb(212, 212, 230);
                border: 0;
                border-bottom: 2px solid #b8b8c5;
                margin-top: 0.75rem;
            }
        }
    }
    li.list-group-item {
        background-color: transparent !important;
        &.list-group-item-consended {
            padding: 0.5rem 0 !important;
            margin-bottom: 0 !important;
            border-bottom: 1px solid #dbdbe7;
            &:last-child {
                border-bottom: 0;
            }
        }
    }
}