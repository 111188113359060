div.searchbar {
    background: #ffffff;
    padding: 0.25rem;
    input {
        font-size: 1.5rem;
        display: block;
        width: 100%;
        border: 0;
        outline: none;
        color: #9d9aa8;
    }
    border-bottom: 1Px solid rgb(225, 225, 245);
}