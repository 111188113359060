.form-control {
  border: none !important;
  background-color: #e9edf1;
  border-radius: 0.35rem !important;
  padding: 0.75rem 1.25rem !important;
  font-size: 1rem !important;
  transition: all 0.45s;
  &::placeholder {
    color: rgb(149, 167, 180);
  }
  &:focus {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 4px rgba(6, 90, 247, 0.2);
    &:after {
      box-shadow: 0;
    }
  }
  &.form-control-lg {
    font-size: 1rem !important;
  }
  &.form-control-flat {
    box-shadow: none !important;
    background-color: hsl(212deg, 28%, 96%);
    color: hsl(212deg, 80%, 20%);
    &:focus {
      box-shadow: none !important;
      background-color: hsl(212deg, 28%, 94%);
      color: hsl(212deg, 80%, 50%);
    }
  }
}

label {
  font-size: 1rem !important;
  color: hsl(212deg, 80%, 20%);
  font-weight: 600;
  &.col-form-label {
    font-size: 1rem !important;
    &.col-form-label-lg {
      font-size: 1rem !important;
    }
  }
}
