.zbadge {
    font-weight: 600;
    font-size: 1rem;
    display: inline-block;
    border-radius: 40px;
    width: 5rem;
    height: 5rem;
    line-height: 5rem;
    text-align: center;
    box-sizing: border-box;
    background: #3f3f4c;
    color: #ffffff;
    font-family: "Cera Pro Bold";
    margin: 0.5rem;
    &.zbadge-hg {
        background: #3d3d8f;
        color: #ffd000;
    }
}