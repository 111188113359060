.table {
    &.table-tickets,
    &.table-labour-requests,
    &.table-transport-damages {
        thead {
            border: 0;
            border-bottom: 0;
            tr {
                th {
                    font-size: 0.85rem;
                    border-top: 0;
                    border-bottom: 1px solid rgb(152, 152, 206) !important;
                    line-height: 1rem;
                }
            }
        }
        tbody {
            font-size: 0.9rem;
            tr {
                td {
                    border-bottom: 1px solid rgb(225, 225, 245) !important;
                    line-height: 1.5rem;
                    padding-top: 1.5rem;
                    padding-bottom: 1.5rem;
                    &.no-border-bottom {
                        border-bottom: none !important;
                    }
                    &.double-border-bottom {
                        border-bottom: 1px solid rgb(152, 152, 206) !important;
                    }
                }
                td.identifier {
                    vertical-align:middle
                }
                td.downloads {
                    display: flex;
                    flex-direction: row;
                }
                td.status {
                    vertical-align: middle;
                    text-align: right;
                }
                td.ticket-info {
                    hr {
                        border-top: 4px dotted rgb(96, 139, 180);
                        background-color: #fff;
                        margin: 4px 0;
                        width: 80px;
                    }
                }
            }
        }
    }
}

.table-primary {
    td, tr, th {
        background-color: rgb(235, 240, 250);
        background-color: white;
    }
}
.table-white {
    td, tr, th {
        background-color: rgb(250, 252, 255);
        background-color: white;
    }
}
.table-success {
    td, tr, th {
        background-color: rgb(237, 248, 244);
    }
}
.table-closed {
    td, tr, th {
        background-color: rgb(236, 236, 236);
    }
}