.tile {
  border-radius: 0.25rem;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  .cover {
    position: relative;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 45, 128, 0.5);
    transition: all 0.25s;
    &.video-overlay {
      background: linear-gradient(
        135deg,
        rgba(202, 7, 7, 0.05),
        rgba(202, 7, 7, 0.25),
        rgba(202, 7, 7, 0.75)
      );
      background: rgba(0, 0, 0, 0.6);
    }
  }
  .info {
    bottom: 10px;
    left: 10px;
    display: flex;
    min-height: 100%;
    align-items: flex-end;
    position: absolute;
    padding: 1rem 1.25rem;
    line-height: 1rem;
    color: #ffffff;
    transition: all 0.25s;
    //text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.5);
    h3,
    a h3 {
      font-size: 1.05rem;
      font-weight: 600;
      color: #ffffff;
      &:hover {
        color: #ffffff;
      }
    }
    &.video-info {
      background: url("/img/play120.png");
      background-size: auto;
      background-position: left bottom;
      background-repeat: no-repeat;
    }
  }
  &:hover {
    .overlay {
      background: rgba(28, 105, 248, 0.8);
      &.video-overlay {
        background: rgba(109, 2, 2, 0.75);
      }
    }
    .info {
      padding-bottom: 1.25rem;
    }
  }
}
