.card {
  &.sml-card {
    border: none !important;
    border-radius: 0.5rem !important;
    font-size: 1rem;
    font-family: "Cera Pro Regular";
    background-color: #f3f8fb;
    overflow: hidden;
    &.sml-card-button {
      cursor: pointer !important;
      font-family: "Cera Pro Medium";
      text-align: center;
      transition: all 0.25s;
    }
    &.sml-card-yellow {
      background-color: #fbfaf3;
    }
    div.card-cover {
      display: block;
      position: relative;
      overflow: hidden;
    }
    div.card-header {
      background-color: transparent !important;
      border-bottom: none !important;
    }
    div.card-body {
      padding: 1.5rem 3rem;
    }
    div.card-footer {
      background-color: transparent !important;
      border-top: none !important;
      font-size: 0.85rem;
    }
  }
  &.sml-card-gray {
    background-color: #f5f6f9;
    color: #3b3a52;
    &.sml-card-button {
      &:hover {
      }
    }
  }
  &.sml-card-blueberry {
    background-color: #f3f5fc;
    color: #3b3a52;
  }
  &.sml-card-green {
    background-color: #f6fcf3;
    color: #3a5240;
  }
  &.sml-card-blue {
    background-color: #f3fcfc;
    color: #3a5252;
  }
  &.sml-card-raspberry {
    background-color: #fdf1f3;
    color: #390912;
  }
  &.sml-card-orange {
    background-color: #fdf4f1;
    color: #391409;
  }
  &.sml-card-yellow {
    background-color: #fdfbf1;
    color: #392e09;
  }

  &.sml-card-primary {
    background-color: #f0f5f9;
    color: #103f56;
    &.sml-card-button {
      &:hover {
        background-color: #c1dff5;
        color: #103f56;
      }
    }
  }
  &.sml-card-white {
    background-color: #ffffff;
    color: #0c0c0d;
    display: flex;
    &.sml-card-button {
      font-family: "Cera Pro Bold" !important;
      align-items: center;
      justify-content: center;
      &:hover {
        background-color: #e3e9f0;
        color: #0c0c0d;
      }
    }
  }
  &.sml-card-big {
    min-height: 4rem;
  }
}
