.fruity-button {
  font-size: 1rem;
  font-family: "Cera Pro Medium";
  padding: 0.75rem 1.25rem !important;
  border-radius: 0.35rem !important;
  transition: all 0.25s;
  background-color: #e3e3ee;
  color: #62627a;
  &.fruity-sm {
    font-size: 0.85rem;
    padding: 0.35rem 1rem !important;
    border-radius: 0.25rem !important;
  }
  &.fruity-xs {
    font-size: 0.75rem;
    padding: 0.2rem 0.75rem !important;
    border-radius: 0.2rem !important;
  }
  &.fruity-lg {
    font-size: 1.25rem;
    padding: 1.75rem 1.25rem !important;
    border-radius: 0.2rem !important;
  }
  &.fruity-xl {
    font-size: 1.5rem;
    padding: 1.75rem 1.5rem !important;
    border-radius: 0.25rem !important;
  }
  &.fruity-blueberry {
    $background_color: #dde1fc;
    $color: #23307c;
    background: $background_color;
    color: $color;
    &:hover {
      background-color: #b6c0fa;
      color: #23307c;
    }
    &.fruity-active {
      background-color: #b6c0fa;
      color: #23307c;
    }
  }
  &.fruity-mint {
    $background_color: #c6f3da;
    $color: #113521;
    background: $background_color;
    color: $color;
    &:hover {
      background-color: #2bf184;
      color: #113521;
    }
    &.fruity-active {
      background-color: #2bf184;
      color: #113521;
    }
  }
  &.fruity-raspberry {
    $background_color: #fce6eb;
    $color: #e9103f;
    background: $background_color;
    color: $color;
    &:hover {
      background-color: $color;
      color: $background_color;
    }
    &.fruity-active {
      background-color: $color;
      color: $background_color;
    }
  }
  &.fruity-icemint {
    $background_color: #e8eced;
    $color: #1b7eab;
    background: $background_color;
    color: $color;
    &:hover {
      background-color: $color;
      color: $background_color;
    }
    &.fruity-active {
      background-color: $color;
      color: $background_color;
    }
  }
  &.fruity-orange {
    $background_color: #f2ebe7;
    $color: #f27900;
    background: $background_color;
    color: $color;
    &:hover {
      background-color: $color;
      color: $background_color;
    }
    &.fruity-active {
      background-color: $color;
      color: $background_color;
    }
  }
  &.fruity-gray {
    $background_color: #d2d9db;
    $color: #6a6e70;
    background: $background_color;
    color: $color;
    &:hover {
      background-color: #b0bdc0;
      color: #303233;
    }
    &.fruity-active {
      background-color: #b0bdc0;
      color: #303233;
    }
  }
  &.fruity-white {
    $background_color: #ffffff;
    $color: #2f2e3d;
    background: $background_color;
    color: $color;
    &:hover {
      background-color: #d0cee4;
      color: #2f2e3d;
    }
    &.fruity-active {
      background-color: #d0cee4;
      color: #2f2e3d;
    }
  }
}
